<template>
  <div>
    <div class="creator_detail_backImg">
      <img :src="returnImage()" width="1200" height="310" alt="">
    </div>
    <div class="creator_detail_info_box">
      <div class="creator_detail_profileImg mb10">
        <div class="profileImg">
          <img :src="returnMainImage()" width="180" height="180" alt="">
        </div>
        <div class="user_util_box collect_util">
          <div class="user_user_like_wrap">
            <div class="like_img_wrap">
              <div class="like_img cursor_none"></div>
              <span class="like_count">{{ returnCommas(collectData.Likes.cnt) }}</span>
            </div>
          </div>
          <div class="user_share_box">
            <share-collect-component class="in_block" :collectData="collectData"></share-collect-component>
          </div>
        </div>
        <div class="creator_detail_nick collect_detail_nick">
          <span class="creator_nick">{{ collectData.c_title }}</span>
          <div class="collect_member_nick" v-if="collectData.mb_is_use === 1"
               @click="movePage(`/creator/${collectData.mb_uuid}`)">
            <div class="collect_member_img">
              <img :src="returnProfile()" width="25" height="25">
            </div>
            {{ collectData.mb_nick }}
          </div>
        </div>
      </div>
      <div style="text-align: right">
        <button class="btn-collect-shortcut" v-if="checkLaunch()" @click="moveLaunch()">{{
            $t('shortcut_launch')
          }}
        </button>
        <div class="collect_detail_info">
          <div class="collect_info_text">
            <div class="collect_info_title">Item</div>
            <div class="collect_info_title">Owner</div>
          </div>
          <div class="collect_info_text">
            <div class="collect_info_content">{{ returnPriceString(collectData.item_cnt) }}</div>
            <div class="collect_info_content"> {{ returnPriceString(collectData.owner_cnt) }}</div>
          </div>
          <div class="collect_info_text">
            <div class="collect_info_title">Floor price</div>
            <div class="collect_info_title">Volume traded</div>
          </div>
          <div class="collect_info_text">
            <div class="collect_info_content collect_price">
              <div class="price">
                {{ returnPriceString(returnMinMingle()) }}Mg
              </div>
              <div class="item_small">
                ({{ returnPriceStringMingle(returnMinMingle()) }})
              </div>
            </div>
            <div class="collect_info_content collect_price">
              <div class="price">
                {{ returnPriceString(collectData.c_total) }}Mg
              </div>
              <div class="item_small">
                ({{ returnPriceStringMingle(collectData.c_total) }})
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="clear"></div>

      <div class="create_detail_intro_wrap mt0 mb30">
        <div class="creator_detail_intro ql-editor pl0 pr0" v-html="replaceBr(collectData.c_intro)"></div>
      </div>
    </div>
  </div>
</template>

<script>
import util from "@/mixins/util";
import LikeCollectionComponent from "@/components/like/LikeCollectionComponent";
import ShareComponent from "@/components/share/ShareComponent";
import imageResizeMixins from "@/mixins/imageResizeMixins";
import priceMixins from "@/mixins/priceMixins";
import ShareCollectComponent from "@/components/share/ShareCollectComponent";

export default {
  name: "CollectDetailDefaultLayout",
  mixins: [imageResizeMixins, priceMixins],
  components: {ShareCollectComponent, ShareComponent, LikeCollectionComponent,},
  inject: [],
  provide() {
    return {}
  },
  props: {
    collectData: {
      default: () => {
      }
    },
    launchUrl: '',

  },
  data() {
    return {}
  },
  beforeRouterEnter() {
  },
  created() {
  },
  mounted() {

  },
  beforeDestroy() {
  },
  destroyed() {
  },
  computed: {},
  methods: {
    movePage(url) {
      this.$router.push(url);
    },
    returnImage() {
      if (!util.isEmpty(this.collectData) && !util.isEmpty(this.collectData.CollectionFiles) && !util.isEmpty(this.collectData.CollectionFiles.BgFile)) {
        return `${this.collectData.CollectionFiles.BgFile[0].org_url}?${this.setImageOptions(1200, 310, 'webp')}`;

      } else {
        return '';
      }
    },
    returnMainImage() {
      if (!util.isEmpty(this.collectData) && !util.isEmpty(this.collectData.CollectionFiles) && !util.isEmpty(this.collectData.CollectionFiles.MainFile)) {
        return `${this.collectData.CollectionFiles.MainFile[0].org_url}?${this.setImageOptions(180, 180, 'webp')}`;
      }
    },
    returnProfile() {
      if (!util.isEmpty(this.collectData) && !util.isEmpty(this.collectData.OwnerFile) && !util.isEmpty(this.collectData.OwnerFile.Profile)) {
        return `${this.collectData.OwnerFile.Profile[0].org_url}?${this.setImageOptions(18, 18, 'webp')}`;
      }
    },
    replaceBr(content) {
      if (util.isEmpty(content)) {
        return '';
      }
      return content.replace(/(\n|\r\n)/g, '<br>');
    },
    returnMinMingle() {
      if (!util.isEmpty(this.collectData.min_price)) {
        return this.collectData.min_price
      } else {
        return 0
      }
    },
    checkLaunch() {
      return !util.isEmpty(this.launchUrl);
    },
    moveLaunch() {
      window.open(this.launchUrl, '')
    }

  },
  watch: {},
}
</script>

<style scoped>

</style>