<template>
    <button class="share_img_wrap" @click="openShareModal">
        <div class="share_img"></div>
    </button>
</template>

<script>

export default {
    name: "ShareCollectComponent",
    components: {},
    provide: function () {
        return {}
    },
    props: {
        collectData: {}
    },
    data() {
        return {}
    },
    created() {
    },
    mounted() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        openShareModal() {
            this.$modal.show('share-modal', {collectData: this.collectData})
        }
    },
}
</script>

<style scoped>

</style>
